import React from 'react';
import './HeaderQuienesSomos.css';

function HeaderQuienesSomos() {
  return (
    <header className="header-quienes-somos">
      <h1>¿Quiénes somos?</h1>
    </header>
  );
}

export default HeaderQuienesSomos;
