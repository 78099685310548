import React from 'react';
import './Footer.css';

function FooterAnuncios() {
  return (
    <footer className="footer-anuncios">
      © 2024, Tu Primer Negocio. Todos los derechos reservados.
    </footer>
  );
}

export default FooterAnuncios;
