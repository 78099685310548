import React from 'react';
import './HeaderContactenos.css';

function HeaderContactenos() {
    return (
        <div className="header-contactenos">
            <h1>Contáctenos</h1>
        </div>
    );
}

export default HeaderContactenos;
