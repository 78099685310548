import React from 'react';
import './HeaderHome.css';

const HeaderHome = () => {
  return (
    <header className="header-home">
      {/* <h1>Tu Primer Negocio</h1> */}
    </header>
  );
};

export default HeaderHome;
